import { Controller } from "@hotwired/stimulus"
import AirDatepicker from 'air-datepicker'
import localeRu from 'air-datepicker/locale/ru'

export default class extends Controller {
  connect() {
    this.picker = new AirDatepicker(this.element, {
      minDate: '01.01.1900',
      maxDate: new Date(),
      locale: localeRu,
      dateFormat: "dd.MM.yyyy",
      autoClose: false,
      onSelect: ({ formattedDate }) => {
        this.element.value = formattedDate;
      },
      onChangeView: true
    })
    this.element.addEventListener('input', this.handleManualInput.bind(this))
  }

  handleManualInput(event) {
    const inputValue = event.target.value;
    const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;

    if (datePattern.test(inputValue)) {
      const formattedDate = this.formatDate(inputValue);

      this.picker.selectDate(formattedDate);
  
      this.picker.show();
    }
  }

  formatDate(inputValue) {
    const parts = inputValue.split('.');
    const [parsedDate, parsedMonth, parsedYear] = parts;

    const day = parseInt(parsedDate);
    const month = (parseInt(parsedMonth) - 1);
    const year = parseInt(parsedYear);

    return new Date(year, month, day);
  }
}
